import React, { useEffect, useState } from "react";
import Banner from "./components/Banner/Index";
import { CircularProgress } from "@mui/material";
import Title from "./components/Title/Index";
import FillShape from "./images/Vectors/FillShape";
import Accordions from "./components/Accordions/Index";
import "./components/Accordions/index.css";
import WebContainer from "./components/WebContainer/Index";
import GallerySlider from "./components/Gallery/GallerySlider";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import Careerdata from "./Json/Career.json";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Work from "./images/Vectors/Work";
import axios from "axios";
import { API_BASE_URL } from "./Utils/useApi";

const Career = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState("panel0");
  const [nestedExpanded, setNestedExpanded] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setNestedExpanded({}); // Close all nested accordions when a parent accordion is toggled
  };

  const handleNestedChange =
    (parentIndex, nestedIndex) => (event, isExpanded) => {
      setNestedExpanded({
        [`${parentIndex}-${nestedIndex}`]: isExpanded,
      });
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/career2.php`);
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setloading(false);
      }
    };
    fetchData();
  }, []);
  if (loading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if (error) return <div>Error: An error occurred while fetching data</div>;
  if (!pageData) return null;
  const { bannerData, overview, gallery, careerData } = pageData;
  console.log("carrerdata,", careerData.currentOpening);
  return (
    <main>
      {bannerData && <Banner Data={bannerData} />}
      {overview && (
        <WebContainer
          _parentClass="Overview"
          containerClass={"position-relative "}
        >
          <div className="col-12 float-start position-relative">
            <div className="overviewSign">
              <FillShape />
            </div>
            <Title
              firstHeading={overview.Title}
              secondHeading={overview.subTitle}
              subTitle={overview.descp}
            />
          </div>
        </WebContainer>
      )}
      <WebContainer>
        <Title
          firstHeading={careerData.careerTitle}
          secondHeading={careerData.careerHeading}
          subTitle={
            "We are offering excellent opportunities for <strong>Managers</strong>, <strong>Assistant Managers</strong>, and <strong>Trainees </strong>across departments."
          }
        />
        <div className="CurrentOpenings ">
          {careerData.currentOpening.map((locationItem, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className="bg-transparent border-0 box-shadow-none accordionDesign  removeAfter  lcn_accordion "
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                className="titleAccordion px-0 Lcn_heading"
              >
                <div className="fw-600 text-uppercase">
                  {locationItem.category}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="accordionDesign  col-12 float-start">
                  {locationItem.openings.map((career, nestedIndex) => (
                    <Accordion
                      key={nestedIndex}
                      expanded={
                        nestedExpanded[`${index}-${nestedIndex}`] || false
                      }
                      onChange={handleNestedChange(index, nestedIndex)}
                      className="nestedAccordionDesign bg-transparent  border-0 box-shadow-none accordionDesign removeAfter rowBorder mx-3"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`nested-panel${nestedIndex}-content`}
                        id={`nested-panel${nestedIndex}-header`}
                        className="titleAccordion px-0"
                      >
                        <div className="fw-600 text-uppercase nested_acc_text">
                          {career.title}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul className=" col-12 float-start">
                          {career.description && (
                            <li>
                              <span>
                                <Work />
                              </span>
                              <p>{career.description}</p>
                            </li>
                          )}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
          {/* <Accordions
            Data={data.careerData.currentOpeningByLocation}
            showCareer={true}
            showNested={true}
          /> */}
        </div>
      </WebContainer>
      {gallery && (
        <div className="col-12 float-start iecLife px-3">
          <GallerySlider Data={gallery} />
        </div>
      )}
      <BusinessEnquiry />
    </main>
  );
};

export default Career;
